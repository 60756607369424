import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { MasterDefinitionService } from "../../../services/master.definitions/master.definition.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { MasterDefsUsage } from "../../../pojo/MasterDefs";

@TypeManagerDecorator("s25-ng-master-definitions-usage")
@Component({
    selector: "s25-ng-master-definitions-usage",
    template: `<div class="usage-cell">
        <s25-loading-inline [model]="{}"></s25-loading-inline>

        <s25-popover [modelBean]="{ popoverTemplate: usageTemplate }" [openTrigger]="'click'" [closeTrigger]="'click'">
            @if (!eventTypeHierarchy) {
                <button class="aw-button aw-button--outline" (click)="initFunc()">View</button>
            }
        </s25-popover>

        <ng-template #usageTemplate>
            @if (init) {
                <div class="master-defs--usage-wrapper">
                    <h3 class="c-margin-bottom--half">{{ usage.master_name }}</h3>
                    @if (usage.object.length > 0 && !eventTypeHierarchy) {
                        <div class="list-container c-margin-bottom--single" tabindex="0">
                            <div class="usage-header c-margin-bottom--quarter">
                                <b>Object Usage</b>
                            </div>
                            <ul>
                                @for (obj of usage.object; track obj) {
                                    <li>
                                        <span>{{ obj.object_name }}</span>
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                    @if (usage.object?.length === 0) {
                        <div>No objects use this master definition</div>
                    }
                    @if (usage.search.length > 0) {
                        <div class="list-container" tabindex="0">
                            <div class="usage-header c-margin-bottom--quarter">
                                <b>Search Usage</b>
                            </div>
                            <ul>
                                @for (search of usage.search; track search) {
                                    <li>
                                        <span>{{ search.search_name }}</span>
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                    @if (usage.search?.length === 0) {
                        <div>No searches use this master definition</div>
                    }
                </div>
            }
        </ng-template>
    </div>`,
    styles: `
        .master-defs--usage-wrapper {
            padding: 1em;
            background-color: #fafafa;
        }

        .list-container {
            border: 1px solid slategray;
            border-radius: 10px;
            padding: 10px;
            background-color: #fff;
            max-height: 40vh;
            overflow-y: auto;
        }

        .usage-header {
            position: sticky;
            top: -10px;
            background-color: #fff;
        }

        :host ::ng-deep .s25-text {
            display: none !important;
        }

        .usage-cell {
            position: relative;
        }

        :host ::ng-deep s25-loading-inline {
            position: absolute;
            left: 6.5em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MasterDefinitionsUsageComponent {
    @Input() type: string;
    @Input() itemId: number;
    @Input() eventTypeHierarchy?: boolean = false;

    init: boolean;
    usage: MasterDefsUsage;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async initFunc() {
        S25LoadingApi.init(this.elementRef.nativeElement);

        this.usage = await MasterDefinitionService.getUsage(this.type, this.itemId);

        this.init = true;
        this.cd.detectChanges();
        S25LoadingApi.destroy(this.elementRef.nativeElement);

        setTimeout(() => {
            const listContainer: HTMLElement = document.querySelector(".list-container");
            listContainer?.focus();
        }, 500);
    }
}
