//@author: mandy
import { DataAccess } from "../../../../dataaccess/data.access";
import { S25Util } from "../../../../util/s25-util";
import { Cache } from "../../../../decorators/cache.decorator";
import { UserprefService } from "../../../../services/userpref.service";
import { jSith } from "../../../../util/jquery-replacement";

export class DashService {
    @Cache({ targetName: "DashService" })
    public static get() {
        return DataAccess.get(DataAccess.injectCaller("/home/dash/dash.json", "DashService.get")).then((data) => {
            let dash = data.dash;
            dash.sections &&
                dash.sections.map(function (section: any) {
                    section.panels &&
                        (section.panels = section.panels.reduce(function (result: any, panel: any) {
                            //exclude panelId=11 'Recently Viewed'
                            return parseInt(panel.panelId) !== 11 ? result.concat(panel) : result;
                        }, []));
                });
            return dash;
        });
    }

    public static refresh() {
        return DashService.get();
    }

    public static reset() {
        return Promise.resolve(
            UserprefService.getLoggedIn().then(function (loggedIn) {
                // if not logged-in - no work to do, resolve to current cached dash
                if (!loggedIn) {
                    DashService.get().then(function (data) {
                        return data;
                    });
                } else {
                    return DataAccess.put(DataAccess.injectCaller("/home/dash/reset.json", "DashService.reset")).then(
                        (data) => {
                            return data.dash;
                        },
                    );
                }
            }),
        );
    }

    public static putPanelsOrder(data: any) {
        return Promise.resolve(
            UserprefService.getLoggedIn().then(function (loggedIn) {
                // if not logged-in - no work to do
                if (!loggedIn) {
                    return;
                } else {
                    let filterModel = function (data: any) {
                        let sectionsArr: any = [];
                        jSith.forEach(data.sections, function (key: any, value: any) {
                            let panelsArr: any = [];
                            jSith.forEach(value.panels, function (panelkey: any, panelvalue: any) {
                                panelsArr.push({ panelId: panelvalue.panelId });
                            });
                            sectionsArr.push({ sectionId: value.sectionId, panels: panelsArr });
                        });
                        return { dash: { sections: sectionsArr } };
                    };
                    data = S25Util.toJson(filterModel(data));
                    return DataAccess.put(
                        DataAccess.injectCaller("/home/dash/panels-order.json", "DashService.putPanelsOrder"),
                        data,
                    ).then((data) => {
                        return data;
                    });
                }
            }),
        );
    }
}
