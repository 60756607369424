//@author: mandy
import { Cache } from "../../../../decorators/cache.decorator";
import { LangService } from "../../../../services/lang.service";
import { DashLangMap } from "./dash.lang.map";

export class DashLangService {
    @Cache({ targetName: "DashLangService" })
    public static getLang() {
        return LangService.getLang().then((data) => {
            return DashLangMap.getLang(data);
        });
    }
}
