import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MasterDefTag } from "../../pojo/MasterDefTag";
import MasterDefI = MasterDefTag.MasterDefI;

@Component({
    selector: "s25-md-tag-item-collapse",
    template: `
        <div class="simple-collapse--wrapper c-margin-top--single c-objectDetails--borderedSection">
            <div class="c-sectionHead">
                <h3 class="simpleCollapseLabel">
                    {{ masterDef.displayName }} ({{ masterDef.active ? masterDef.items.length : "Disabled" }})
                </h3>
            </div>
            <s25-simple-collapse [defaultCollapsed]="true">
                <div class="ngBlock c-margin-top--single c-objectDetails c-margin-left--single">
                    <s25-toggle-button
                        [(modelValue)]="masterDef.active"
                        (modelValueChange)="radioChange(masterDef, $event)"
                        [trueLabel]="'Enable'"
                        [falseLabel]="'Disable'"
                    ></s25-toggle-button>
                    @if (masterDef.active) {
                        <p class="ngFinePrint c-margin-bottom--half">
                            When enabled, users in assigned security group(s) will see the items on this tag and any
                            other tags their group has permissions to.
                        </p>
                    }
                    @if (!masterDef.active) {
                        <p class="ngFinePrint c-margin-bottom--half">
                            When disabled, this will have no impact in which users see these items.
                        </p>
                    }

                    <s25-ng-multiselect-search-criteria
                        [type]="masterDef.searchCriteriaName"
                        [selectedItems]="masterDef.items"
                        [modelBean]="masterDef.multiSelectModel"
                        honorMatching="true"
                        [popoverOnBody]="true"
                        [useSecurity]="false"
                    ></s25-ng-multiselect-search-criteria>
                </div>
            </s25-simple-collapse>
        </div>
    `,
})
export class S25MdTagItemCollapseComponent {
    @Input() masterDef: MasterDefI;
    @Output() masterDefChange: EventEmitter<MasterDefI> = new EventEmitter<MasterDefTag.MasterDefI>();

    radioChange(md: MasterDefI, event: boolean) {
        md.changed = true;
    }
}
