import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { Bind } from "../../decorators/bind.decorator";
import { MasterDefinitionTagsService } from "../../services/master.definitions/master.definition.tags.service";
import { MasterDefTag } from "../../pojo/MasterDefTag";
import { TypeManagerDecorator } from "../../main/type.map.service";
import TagWrapperI = MasterDefTag.TagWrapperI;
import MasterDefI = MasterDefTag.MasterDefI;
import types = MasterDefTag.types;

@TypeManagerDecorator("s25-edit-master-definition-tag")
@Component({
    selector: "s25-edit-master-definition-tag",
    template: `
        @if (isInit) {
            <div>
                <div class="tagRow">
                    <label class="ngBold" for="tag-name">Tag Name:</label>
                    <input
                        [(ngModel)]="masterListTags.tag_name"
                        id="tag-name"
                        class="c-input"
                        name="tag-name"
                        type="text"
                        minlength="2"
                        maxlength="40"
                        required
                    />
                </div>
                <div class="tagRow">
                    <label class="ngBold" for="tag-comment">Description:</label>
                    <textarea
                        [(ngModel)]="masterListTags.comment"
                        rows="2"
                        cols="60"
                        class="cn-form__control"
                        id="tag-comment"
                    ></textarea>
                </div>
                <div class="ngBold">Categories</div>
                @for (md of categories; track md; let i = $index) {
                    <div>
                        <s25-md-tag-item-collapse [(masterDef)]="categories[i]"></s25-md-tag-item-collapse>
                    </div>
                }
                <div class="ngBold">Features</div>
                <s25-md-tag-item-collapse [(masterDef)]="locationFeatures"></s25-md-tag-item-collapse>
                <div class="ngBold">Manual Email Templates</div>
                <s25-md-tag-item-collapse [(masterDef)]="emailTemplates"></s25-md-tag-item-collapse>
            </div>
        }
    `,
    styles: `
        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }
        .tagRow {
            display: flex;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .tagRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .tagRow > input {
            min-width: 15em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EditMasterDefinitionTagComponent implements OnInit {
    @Input() itemId: number;
    // @Output() errorMessage: EventEmitter<string> = new EventEmitter<string>();

    masterListTags: TagWrapperI;
    categories: MasterDefI[] = [];
    customAttributes: MasterDefI[] = [];
    emailTemplates: MasterDefI = { active: undefined, items: [], md_name: types.email_template };
    locationFeatures: MasterDefI = { active: undefined, items: [], md_name: types.location_features };

    isInit = false;
    errorMsg = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        //make sure that categories can't leak from one form to another
        this.categories = [];
        await this.getTagData();
        this.masterListTags.members.forEach((item) => {
            item.multiSelectModel = { showResult: true, showMatching: false, preFetchItems: false, hasSelectAll: true };
            if (item.md_name.includes("categories")) {
                this.categories.push(item);
            } else if (item.md_name.includes("attribute")) {
                this.customAttributes.push(item);
            } else if (item.md_name === MasterDefTag.types.email_template) {
                this.emailTemplates = item;
            } else if (item.md_name === MasterDefTag.types.location_features) {
                this.locationFeatures = item;
            }
        });

        this.isInit = true;
        this.cd.detectChanges();
    }

    @Bind
    async getTagData() {
        this.masterListTags = (await MasterDefinitionTagsService.getTag(this.itemId))[0];
        this.errorMsg = "";
    }

    validate() {
        this.errorMsg = "";
        if (!this.masterListTags.tag_name || this.masterListTags.tag_name.length < 2) {
            this.errorMsg = "A name longer than two characters is required";
            return false;
        }

        return true;
    }

    @Bind
    async save() {
        if (!this.validate()) return Promise.reject(this.errorMsg);
        this.errorMsg = "";
        let payload: TagWrapperI = {
            tag_id: this.itemId || undefined,
            tag_name: this.masterListTags.tag_name,
            comment: this.masterListTags.comment,
            members: this.masterListTags.members.map((item) => {
                return {
                    md_name: item.md_name,
                    changed: item.changed,
                    active: item.active ? 1 : 0,
                    items: item.items, // not needed
                    addedItems: item.multiSelectModel.addedItems,
                    removedItems: item.multiSelectModel.removedItems,
                };
            }),
        };

        return await MasterDefinitionTagsService.updateTag(this.itemId, payload);
    }
}
