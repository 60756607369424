import { S25Util } from "../../util/s25-util";
import { DataAccess } from "../../dataaccess/data.access";
import { Cache, Invalidate } from "../../decorators/cache.decorator";
import { S25Const } from "../../util/s25-const";
import { TelemetryService } from "../../services/telemetry.service";

export interface Integration {
    enabled: boolean;
    apiKey: string;
    integrationType: IntegrationType;
    integrationId?: number;
    instanceId?: string;
    url?: string;
}

export const enum IntegrationTypes {
    stripe = "stripe",
    sevenPoint = "sevenPoint",
}

export type IntegrationType = IntegrationTypes.stripe | IntegrationTypes.sevenPoint;

export class IntegrationService {
    @Cache({ immutable: true, targetName: "IntegrationService" })
    public static async getIntegration(integrationType: IntegrationType) {
        let [integration, error] = await S25Util.Maybe(
            DataAccess.get<Integration>(`/integrations/${integrationType}.json`),
        );
        if (error) {
            return null;
        }
        return integration;
    }

    @Cache({ immutable: true, targetName: "IntegrationService" })
    public static async getIntegrationEnabled(integrationType: IntegrationType) {
        let integration = await IntegrationService.getIntegration(integrationType);
        return !!(integration && integration.enabled);
    }

    @Invalidate({ serviceName: "IntegrationService", methodName: "getIntegration" })
    @Invalidate({ serviceName: "IntegrationService", methodName: "getIntegrationEnabled" })
    public static async setEnabled(integrationId: number, integrationType: IntegrationType, enabled: boolean) {
        TelemetryService.sendWithSub(
            "SysSettings",
            "Integrations",
            S25Util.firstCharToUpper(integrationType) + (enabled ? "On" : "Off"),
        );
        return DataAccess.put(`/integrations/${integrationId}/enabled.json`, {
            integrationId: integrationId,
            // required for service to create a persistent session id for the integration, if the integration needs it
            integrationType: integrationType,
            enabled: enabled,
        });
    }

    @Invalidate({ serviceName: "IntegrationService", methodName: "getIntegration" })
    public static async setApiKey(integrationId: number, apiKey: string) {
        return DataAccess.put(`/integrations/${integrationId}/key.json`, {
            integrationId: integrationId,
            apiKey: apiKey,
        });
    }

    @Invalidate({ serviceName: "IntegrationService", methodName: "getIntegration" })
    public static async setUrl(integrationId: number, url: string) {
        return DataAccess.put(`/integrations/${integrationId}/url.json`, {
            integrationId: integrationId,
            url: url,
        });
    }

    @Invalidate({ serviceName: "IntegrationService", methodName: "getIntegration" })
    @Invalidate({ serviceName: "IntegrationService", methodName: "getIntegrationEnabled" })
    public static async createIntegration(integration: Integration): Promise<Integration> {
        return DataAccess.post("/integrations/integration.json", integration);
    }
}
